import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Link, List, Typography } from "@mui/material";
import MenuItem from "./MenuItem";
import { Link as RouterLink } from "react-router-dom";

const MenuByRole = (props) => {
    const [menuList, setMenuList] = useState([]);
    const [version, setVersion] = useState(null);
    const [structureMenu, setStructureMenu] = useState({});

    useEffect(() => {
        setVersion(props?.structureMenu?.version);
        setStructureMenu(props.structureMenu);

        return () => {};
    }, [props.structureMenu]);

    useEffect(() => {
        setMenu();
    }, [props.userType, structureMenu]);

    const setMenu = () => {
        switch (props.userType) {
            case "sitesAdmin":
                // getSiteAdminMenu();
                setMenuList(structureMenu?.menuItems);
                break;
            case "orgAdmin":
                setMenuList(structureMenu?.menuItems);
                break;
            case "multiOrgAdmin":
                setMenuList(structureMenu?.menuItems);
                break;
            case "user":
                setMenuList(structureMenu?.userView?.menuItems);
                // GetStandardMenu();
                break;
            default:
                // Handle default case if needed
                setMenuList([]);
                break;
        }
    };

    return (
        <List
            sx={{
                backgroundColor: "#182434",
                minHeight: "calc(100% - 200px)",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                    width: "0.5em",
                },
                "&::-webkit-scrollbar-track": {
                    background: "#2A2F38", // Track color
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "#949ca7", // Scroll thumb color
                    borderRadius: "4px",
                },
            }}
        >
            {menuList?.map((item, i) => {
                return (
                    <MenuItem
                        key={i}
                        openDrawer={props.open}
                        isMenuItem={item.menuItem}
                        label={item.label}
                        children={item.children ? item.children : undefined}
                        path={item.path}
                        icon={item.icon}
                        subMenu={item.subMenu ? item.subMenu : []}
                        subtitle={item.subtitle}
                        itemType={item.itemType}
                    />
                );
            })}
            {props.open && !!version && (
                <Box component={"li"} display="flex" justifyContent="center">
                    <Link
                        component={RouterLink}
                        to="/portal/versions"
                        sx={{ mt: 4, textDecoration: "none", "&:hover": { textDecoration: "none" } }}
                        color="#949ca7"
                    >
                        <Typography variant="caption">Version: {version}</Typography>
                    </Link>
                </Box>
            )}
        </List>
    );
};

// export default Menu;
const mapStateToProps = (state) => {
    return {
        open: state.componentsReducer.drawerOpen,
        menuType: state.componentsReducer.menuType,
    };
};

export default connect(mapStateToProps)(MenuByRole);
