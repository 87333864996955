import styled from "@emotion/styled";
import { Select } from "@mui/material";

const BootstrapSelect = styled(Select)(({ theme }) => ({
    "label + &": {
        marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
        // borderRadius: 4,
        // position: "relative",
        // backgroundColor: theme.palette.background.paper,
        // border: "1px solid #E0E3E7",
        // fontSize: 16,
        // padding: "10px 26px 10px 12px",
        // transition: theme.transitions.create(["border-color", "box-shadow"]),
        // "&:focus": {
        //     borderColor: theme.palette.primary.main,
        // },

        borderRadius: 4,
        position: "relative",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid",
        borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
        fontSize: 16,
        padding: "10px 12px",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderColor: theme.palette.primary.main,
        },
    },
}));

export default BootstrapSelect;
